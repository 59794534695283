// ------ CRIPTOGRAFIA ------

export function encriptar(Texto)
{
    // Chave (Chave privada para ser usada na criptografia)
    var Chave = "ComFéemDeusNósVenceremos";

    var KeyLen = Chave.length;
    var KeyPos = 0;
    var offset = Math.floor(Math.random() * 100);
    while (offset > 256) 
    {
        offset = Math.floor(Math.random() * 100);
    }
    var dest = (offset.toString(16).toUpperCase().length === 2 ? offset.toString(16).toUpperCase() : "0" + offset.toString(16).toUpperCase());
    for (var SrcPos = 0; SrcPos <= (String(Texto).length - 1); SrcPos++)
    {
        var SrcAsc = (String(Texto).charCodeAt(SrcPos) + offset) % 255;
        SrcAsc = SrcAsc ^ Chave.charCodeAt(KeyPos);
        if (KeyPos < KeyLen - 1)
        {
            KeyPos = KeyPos + 1;
        }
        else
        {
            KeyPos = 0;
        }
        dest = dest + (SrcAsc.toString(16).toUpperCase().length === 2 ? SrcAsc.toString(16).toUpperCase() : "0" + SrcAsc.toString(16).toUpperCase());
        offset = SrcAsc;
    }
    return dest;
}

export function decriptar(Texto)
{
    // Chave (Chave privada para ser usada na criptografia)
    var Chave = "ComFéemDeusNósVenceremos";

    var dest = "";
    var KeyLen = Chave.length;
    var KeyPos = 0;

    //int Range = 256;
    if ((Texto.length > 2) &&
       (Texto.substr(2, 2) !== ""))
    {
        var offset = parseInt(Texto.substr(0, 2), 16);
        var SrcPos = 2;
        while (SrcPos < Texto.length - 1)
        {
            var SrcAsc = parseInt(Texto.substr(SrcPos, 2), 16);
            var TmpSrcAsc = SrcAsc ^ Chave.charCodeAt(KeyPos);
            if (KeyPos < KeyLen - 1)
            {
                KeyPos = KeyPos + 1;
            }
            else
            {
                KeyPos = 0;
            }
            if (TmpSrcAsc <= offset)
            {
                TmpSrcAsc = 255 + TmpSrcAsc - offset;
            }
            else
            {
                TmpSrcAsc = TmpSrcAsc - offset;
            }
            dest = dest + String.fromCharCode(TmpSrcAsc);
            offset = SrcAsc;
            SrcPos = SrcPos + 2;
        }
    }
    else
    {
        dest = "";
    }
    return dest;
}
